<template>
  <div class="pdf_page">
    <pdf
      style="width: 100vw"
      v-for="item in pageTotal"
      :src="pdfUrl"
      :key="item"
      :page="item"
    >
    </pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },

  data() {
    return {
      pdfUrl: "",
      pageTotal: 0,
      url: "",
      // url: "/static/pdf/有信资质帮软著.pdf",
      // url: "https://www.zizhihelp.com/upload/static/108432_A_38.pdf",
    };
  },

  methods: {
    getUrlParams(key) {
      // 获取地址栏参数
      const urlParams =
        window.location.search || window.location.hash.split("?")[1] || "";

      // 获取指定参数值
      const paramValue = decodeURIComponent(urlParams.slice(4));
      return paramValue;
    },
  },

  mounted() {
    document.title = "快照预览";

    this.url = this.getUrlParams("url");

    this.pdfUrl = pdf.createLoadingTask(this.url);

    // 获取页码
    this.pdfUrl.promise
      .then((pdf) => (this.pageTotal = pdf.numPages))
      .catch((error) => {
        console.log("error", error);
      });
  },
};
</script>

<style lang="less" scoped>
.pdf_page {
}
/deep/canvas {
  margin: 5px !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}
</style>
